import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/_guards/auth.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = [
    {
        'path': '',
        'component': AdminComponent,
        'canActivate': [AuthGuard],
        'children': [
            {
                'path': 'admin',
                'loadChildren': '.\/pages\/dashboard\/dashboard.module#DashboardModule',
            },
            {
                'path': 'afiliado',
                'loadChildren': '.\/pages\/afiliado\/afiliado.module#AfiliadoModule',
            },
            {
                'path': 'banners',
                'loadChildren': '.\/pages\/banners\/banners.module#BannersModule',
            },
            {
                'path': 'cupom',
                'loadChildren': '.\/pages\/cupom\/cupom.module#CupomModule',
            },
            {
                'path': 'contatoEstabelecimento',
                'loadChildren': '.\/pages\/contatoEstabelecimento\/contatoEstabelecimento.module#ContatoEstabelecimentoModule',
            },
            {
                'path': 'pedidoAfiliado',
                'loadChildren': '.\/pages\/pedidoAfiliado\/pedidoAfiliado.module#PedidoAfiliadoModule',
            },
            {
                'path': 'premiosAfiliado',
                'loadChildren': '.\/pages\/premiosAfiliado\/premiosAfiliado.module#PremiosAfiliadoModule',
            },
            {
                'path': 'bonus',
                'loadChildren': '.\/pages\/bonus\/bonus.module#BonusModule',
            },
            {
                'path': 'resgate',
                'loadChildren': '.\/pages\/resgate\/resgate.module#ResgateModule',
            },
            {
                'path': 'troca',
                'loadChildren': '.\/pages\/troca\/troca.module#TrocaModule',
            },
            {
                'path': 'produto',
                'loadChildren': '.\/pages\/produto\/produto.module#ProdutoModule',
            },
            {
                'path': 'estabelecimento',
                'loadChildren': '.\/pages\/estabelecimento\/estabelecimento.module#EstabelecimentoModule',
            },
            {
                'path': 'empresa',
                'loadChildren': '.\/pages\/empresas\/empresa.module#EmpresaModule',
            },
            {
                'path': 'pedidoEstabelecimento',
                'loadChildren': '.\/pages\/pedidoEstabelecimento\/pedidoEstabelecimento.module#PedidoEstabelecimentoModule',
            },
            {
                'path': 'ranking',
                'loadChildren': '.\/pages\/ranking\/ranking.module#RankingModule',
            },
            {
                'path': 'rankingPromocao',
                'loadChildren': '.\/pages\/rankingPromocao\/rankingPromocao.module#RankingPromocaoModule',
            },
            {
                'path': 'usuario',
                'loadChildren': '.\/pages\/usuario\/usuario.module#UsuarioModule',
            },
            {
                'path': 'organizador',
                'loadChildren': '.\/pages\/organizador\/organizador.module#UsuarioModule',
            },
            {
                'path': 'giftEstabelecimento',
                'loadChildren': '.\/pages\/servicoGift\/servicoGift.module#ServicoGiftModule',
            },
            {
                'path': '',
                'redirectTo': 'admin',
                'pathMatch': 'full',
            },
        ],
    },
    {
        'path': '**',
        'redirectTo': 'admin',
        'pathMatch': 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule { }